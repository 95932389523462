<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>合作伙伴管理</el-breadcrumb-item>
      <el-breadcrumb-item>伙伴申请审核</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="typeId">
            <div style="display: inline-block" class="divSpan">审核状态：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.typeId"
                :options="ResultOptions"
                :optionKey="ParentIdkeys"
                Splaceholder="请选择"
                @clear="getList"
                @change="changeType"
              ></ELselect>
            </div>
          </el-form-item>
          <el-form-item prop="name">
            <div style="display: inline-block" class="divSpan">企业名称：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryinfo.name"
                clearable
                @clear="getList"
              >
              </el-input>
            </div>
          </el-form-item>

          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >
        </el-form>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="ModelList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column
            prop="company"
            label="企业名称"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="userId"
            label="用户ID"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="contact"
            label="联系人"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="phone"
            label="联系手机号"
            min-width="120px"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="申请时间"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="message"
            label="留言内容"
            min-width="250px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="审核状态" min-width="100px"
            ><template slot-scope="scope">
              {{
                scope.row.status == 1
                  ? "审核未通过"
                  : scope.row.status == 2
                  ? "审核已通过"
                  : "待审核"
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="updateTime"
            label="审核时间"
            min-width="150px"
          ></el-table-column>
          <el-table-column label="操作" min-width="150px" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="Approve(scope.row)"
                v-show="scope.row.status == 0"
                >审核</el-button
              >
              <el-tag plain v-show="scope.row.status > 0"> 已审核</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 审核 -->
    <el-dialog
      :close-on-click-modal="false"
      title="审核"
      :visible.sync="ApprovalDialog"
      width="35%"
      @close="ApprovalDialogClosed"
      center
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="120px"
      >
        <el-form-item label="企业名称：" prop="name">
          <el-input v-model.trim="editForm.name" disabled></el-input>
        </el-form-item>
        <el-form-item label="企业简称：" prop="companyAlias">
          <el-input v-model.trim="editForm.companyAlias"></el-input>
        </el-form-item>
        <el-form-item label="推荐码：" prop="referrerPhone">
          <el-input v-model.trim="editForm.referrerPhone" disabled></el-input>
        </el-form-item>

        <el-form-item
          label="审核结果："
          prop="isPassed"
          style="text-align: left"
        >
          <el-radio-group v-model="editForm.isPassed" @change="radioGroups">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="0">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="伙伴等级："
          prop="gradeId"
          v-show="editForm.isPassed == 1"
          :rules="editFormRules.levels"
        >
          <ELselect
            v-model="editForm.gradeId"
            :options="LevelOptions"
            :optionKey="ParentIdkeys"
            Splaceholder="请选择"
          ></ELselect>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="passApproval" v-preventReClick
          >确定</el-button
        >
        <el-button @click="ApprovalDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ELselect from '@/components/myself/el_select'
import { getPartnerAuditList, auditPartner } from '@/api/partner.js'
import { getAllGrade } from './common.js'

export default {
  components: { ELselect },
  data () {
    return {
      queryinfo: {
        typeId: '',
        name: '',
        pageNum: 1,
        pageSize: 20
      },
      total: 0,
      ModelList: [],

      ResultOptions: [
        {
          id: '',
          name: '全部'
        },
        {
          id: 3,
          name: '待审批'
        },
        {
          id: 1,
          name: '审核未通过'
        },
        {
          id: 2,
          name: '审核已通过'
        },
      ],
      ParentIdkeys: {
        value: 'id',
        label: 'name',
        label2: ''
      },
      LevelOptions: [],
      ApprovalDialog: false,
      editForm: {
        name: '',
        isPassed: 1,
        gradeId: '',
        auditId: '',
        companyAlias: '',
        referrerPhone: ''
      },
      editFormRules: {
        levels: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
      },

    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      let quiese = { auditStatus: this.queryinfo.typeId, company: this.queryinfo.name }
      const res = await getPartnerAuditList(this.queryinfo.pageNum, this.queryinfo.pageSize, quiese)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.ModelList = res.data.data.list
      this.total = res.data.data.total
    },
    changeType (val) {
      this.queryinfo.typeId = val
      this.getList()
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getList()
    },
    clean () {
      this.$refs.queryinfoRef.resetFields()
      this.getList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },
    async Approve (para) {
      this.LevelOptions = await getAllGrade(true)
      this.editForm.name = para.company
      this.editForm.auditId = para.id
      this.editForm.referrerPhone = para.referrerPhone
      this.ApprovalDialog = true
    },

    radioGroups (para) {
      if (para === 0) {
        this.editFormRules.levels = []
      } else {
        this.editFormRules.levels = [{ required: true, message: '内容不能为空', trigger: 'blur' }]
      }
    },
    async passApproval () {
      this.$refs.editFormRef.validate(async valid => {
        if (valid) {
          const res = await auditPartner(this.editForm)
          if (res.status !== 200) return this.$message.error('获取失败')
          if (res.data.status === 8000) return this.$message.info("您已是合作伙伴")
          if (res.data.status !== 200) return this.$message.error(res.data.message)
          this.$message.success('操作成功')
          this.ApprovalDialog = false
          this.getList()
        }
      })

    },
    ApprovalDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
  }
}
</script>
<style lang="less" scoped>
.divSpan {
  font-size: 16px;
}
/deep/ .el-form-item__content {
  margin-right: 40px;
}
</style>